import { MasterOption } from "../types/masters";
import { Pagination } from "../types/pagination";
import { IDropdownOptionsService } from "../types/services/dropdown-options-service";
import { FetchResult, GET } from "../utils/fetch";

class DropdownOptionsService implements IDropdownOptionsService {
  async getStaffOptions(
    verticals?: string[],
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/staffs/list",
      qp = new URLSearchParams();
    qp.set("view", "dropdown");

    if (query) {
      qp.set("query", query);
    }
    if (verticals && verticals.length) {
      verticals.forEach((vertical) => qp.append("verticals[]", vertical));
    }

    const qpM = qp.toString();
    let url = base;
    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<StaffOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: response.staffs,
      paginate: response.paginate,
      error: null,
    };
  }

  async getStaffOptionById(id: string): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/staffs/${id}`,
      { response, error } = await GET<StaffOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, value } = response.staff,
      formatted: MasterOption = {
        name: name,
        value: value,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getSipStaffOptions(
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/staffs/poc_list",
      qp = new URLSearchParams();

    qp.set("view", "dropdown");

    if (query) {
      qp.set("query", query);
    }

    const qpM = qp.toString();

    let url = base;

    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<StaffOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = response.staffs.map((each: any) => {
      const { name, sip } = each;

      const formatted: MasterOption = {
        name: name || "-",
        value: sip || "",
      };

      return formatted;
    });

    return {
      response: formatted,
      paginate: response.paginate,
      error: null,
    };
  }

  async getSipStaffOptionById(id: string): Promise<FetchResult<MasterOption>> {
    const base = "/api/v2/staffs/poc_list",
      qp = new URLSearchParams();

    qp.set("view", "dropdown");

    qp.set("query", id);

    const qpM = qp.toString();

    let url = base;

    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<StaffOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, value } = response.staffs[0] ?? {},
      formatted: MasterOption = {
        name: name ?? "-",
        value: value ?? id,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getAgentOptions(
    verticals?: string[],
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/agents/list",
      qp = new URLSearchParams();
    qp.set("view", "dropdown");

    if (query) {
      qp.set("query", query);
    }
    if (verticals && verticals.length) {
      verticals.forEach((vertical) => qp.append("verticals[]", vertical));
    }

    const qpM = qp.toString();
    let url = base;
    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<AgentOptionsPaginated>(url);
    if (error) {
      return {
        response: null,
        error,
      };
    }
    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: response.agents,
      paginate: response.paginate,
      error: null,
    };
  }

  async getAgentOptionById(id: string): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/agents/${id}/dropdown`,
      { response, error } = await GET<AgentOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, value } = response.agent,
      formatted: MasterOption = {
        name: name,
        value: value,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getHomeownerOptions(
    verticals?: string[],
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/rental/home_owners/list",
      qp = new URLSearchParams();
    qp.set("view", "dropdown");

    if (query) {
      qp.set("query", query);
    }
    if (verticals && verticals.length) {
      verticals.forEach((vertical) => qp.append("verticals[]", vertical));
    }

    const qpM = qp.toString();
    let url = base;
    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<HomeownerPaginated>(url);
    if (error) {
      return {
        response: null,
        error,
      };
    }
    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: response.home_owners,
      paginate: response.paginate,
      error: null,
    };
  }

  async getHomeownerOptionById(id: string): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/rental/home_owners/${id}`,
      { response, error } = await GET<HomeownerOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted: MasterOption = {
      name: response.home_owner.name,
      value: response.home_owner.id,
    };

    return {
      response: formatted,
      error: null,
    };
  }

  async getVendorOptions(query?: string): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/vendors",
      qp = new URLSearchParams();
    qp.set("view", "dropdown");

    if (query) {
      qp.set("query", query);
    }

    const qpM = qp.toString();

    let url = base;

    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<VendorOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const vendors = response.vendors.map((each) => {
      const option: MasterOption = {
        name: each.name,
        value: each.id,
      };

      return option;
    });

    return {
      response: vendors,
      paginate: response.paginate,
      error: null,
    };
  }

  async getVendorOptionById(id: string): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/vendors/${id}`,
      { response, error } = await GET<VendorOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, id: value } = response.vendor,
      formatted: MasterOption = {
        name: name,
        value: value,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getCategoryOptions(
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/categories",
      qp = new URLSearchParams();

    if (query) {
      qp.set("query", query);
    }

    const qpM = qp.toString();

    let url = base;

    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<CategoryOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const categories = response.categories.map((each) => {
      const option: MasterOption = {
        name: each.name,
        value: each.id,
      };

      return option;
    });

    return {
      response: categories,
      paginate: response.paginate,
      error: null,
    };
  }

  async getCategoryOptionById(id: string): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/categories/${id}`,
      { response, error } = await GET<CategoryOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, id: value } = response.category,
      formatted: MasterOption = {
        name: name,
        value: value,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getSubCategoryOptions(
    category: string,
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/categories",
      qp = new URLSearchParams();

    qp.set("parent_category_id", category);

    if (query) {
      qp.set("query", query);
    }

    const qpM = qp.toString();

    let url = base;

    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<SubCategoryOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const categories = response.categories.map((each) => {
      const option: MasterOption = {
        name: each.name,
        value: each.id,
      };

      return option;
    });

    return {
      response: categories,
      paginate: response.paginate,
      error: null,
    };
  }

  async getSubCategoryOptionById(
    id: string,
  ): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/categories/${id}`;

    const { response, error } = await GET<SubCategoryOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, id: value } = response.category,
      formatted: MasterOption = {
        name: name,
        value: value,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getPropertyOptions(
    vertical?: string,
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = `/api/v2/${vertical}/properties/list`,
      qp = new URLSearchParams();
    qp.set("view", "dropdown");

    if (query) {
      qp.set("query", query);
    }

    const qpM = qp.toString();
    let url = base;
    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<PropertyOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: response.properties,
      paginate: response.paginate,
      error: null,
    };
  }

  async getPropertyOptionById(id: string): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/development/properties/${id}`,
      { response, error } = await GET<PropertyOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, value } = response.property,
      formatted: MasterOption = {
        name: name,
        value: value,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getPlotOptions(
    verticals?: string[],
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/plots",
      qp = new URLSearchParams();

    if (query) {
      qp.set("query", query);
    }
    if (verticals && verticals.length) {
      verticals.forEach((vertical) => qp.append("verticals[]", vertical));
    }

    const qpM = qp.toString();
    let url = base;
    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<PlotOptionsPaginated>(url);
    if (error) {
      return {
        response: null,
        error,
      };
    }
    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: response.plots,
      error: null,
    };
  }

  async getHouseKeepingOptions(
    verticals?: string[],
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/rental/housekeeping_staff",
      qp = new URLSearchParams();
    qp.set("view", "dropdown");

    if (query) {
      qp.set("query", query);
    }
    if (verticals && verticals.length) {
      verticals.forEach((vertical) => qp.append("verticals[]", vertical));
    }

    const qpM = qp.toString();
    let url = base;
    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<StaffOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: response.staffs,
      paginate: response.paginate,
      error: null,
    };
  }

  async getHouseKeepingStaffOptionById(
    id: string,
  ): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/rental/housekeeping_staff/${id}`,
      { response, error } = await GET<StaffOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, value } = response.staff,
      formatted: MasterOption = {
        name: name,
        value: value,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getMaintenanceOptions(
    verticals?: string[],
    query?: string,
  ): Promise<FetchResult<MasterOption[]>> {
    const base = "/api/v2/rental/maintenance_staff",
      qp = new URLSearchParams();
    qp.set("view", "dropdown");

    if (query) {
      qp.set("query", query);
    }
    if (verticals && verticals.length) {
      verticals.forEach((vertical) => qp.append("verticals[]", vertical));
    }

    const qpM = qp.toString();
    let url = base;
    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<StaffOptionsPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: response.staffs,
      paginate: response.paginate,
      error: null,
    };
  }

  async getMaintenanceStaffOptionById(
    id: string,
  ): Promise<FetchResult<MasterOption>> {
    const url = `/api/v2/rental/maintenance_staff/${id}`,
      { response, error } = await GET<StaffOptionById>(url);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { name, value } = response.staff,
      formatted: MasterOption = {
        name: name,
        value: value,
      };

    return {
      response: formatted,
      error: null,
    };
  }
}

export default DropdownOptionsService;

type StaffOptionsPaginated = {
  staffs: MasterOption[];
  paginate: Pagination;
};

type StaffOptionById = {
  staff: any;
};

type AgentOptionsPaginated = {
  agents: MasterOption[];
  paginate: Pagination;
};

type AgentOptionById = {
  agent: any;
};

type HomeownerPaginated = {
  home_owners: MasterOption[];
  paginate: Pagination;
};

type HomeownerOptionById = {
  home_owner: any;
};

type VendorOptionsPaginated = {
  vendors: any[];
  paginate: Pagination;
};

type VendorOptionById = {
  vendor: any;
};

type CategoryOptionsPaginated = {
  categories: any[];
  paginate: Pagination;
};

type CategoryOptionById = {
  category: any;
};

type SubCategoryOptionsPaginated = {
  categories: any[];
  paginate: Pagination;
};

type SubCategoryOptionById = {
  category: any;
};

type PropertyOptionsPaginated = {
  properties: MasterOption[];
  paginate: Pagination;
};

type PropertyOptionById = {
  property: any;
};

type PlotOptionsPaginated = {
  plots: MasterOption[];
};
